import React, { FC, useState } from 'react';
import { Button, IconButton, MenuItem, Tooltip, Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Expand } from '../../assets/svg';
import { Modal } from '~/components';
import { CustomTextField } from '~/styles';
import { observer, useStores, alert } from '~/utils';

type Props = {
  setAnchorEl: (anchorEl: EventTarget & HTMLButtonElement | null) => void;
};

const AddProject: FC<Props> = ({ setAnchorEl }) => {
  const { project, itemId } = useStores();
  const [openModal, setOpenModal] = useState(false);
  const [inputNewProject, setInputNewProject] = useState('');

  const handleConfirmNewProject = () => {
    if (!inputNewProject.trim()) {
      alert({ message: 'Nome do projeto não pode ser vazio', type: 'warning', position: 'top-center' });
    } else {
      let newId = itemId.generate();
      project.add({
        id: newId,
        name: inputNewProject,
        type: 'custom',
      });
      setInputNewProject('');
      setOpenModal(false);
      project.select(newId);
    }
  };

  return (
    <>
      <Box
        sx={{
          '&:focus': {
            backgroundColor: 'none',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        style={{
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          width: 'auto',
        }}
      >
        <Tooltip title="Adicionar projeto" placement="bottom">
        <IconButton
          onClick={() => setOpenModal(true)}
          sx={{
            '&:hover': {
              backgroundColor: '#FD784E',
            },
          }}
          component="span"
        >
          <Add style={{ color: 'white' }} />
        </IconButton>
        </Tooltip>
      </Box>
    <Modal
      title="Adicionar Projeto"
      open={openModal}
      onClose={() => setOpenModal(false)}
      width={320}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          margin: '8px 0',
        }}
      >
        
        <CustomTextField
          label="Nome do Projeto"
          placeholder="Digite o nome do projeto"
          variant="outlined"
          size="small"
          onChange={(event) => setInputNewProject(event.target.value)}
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === 'Enter') {
              handleConfirmNewProject();
              setAnchorEl(null);
            }
          }}
          inputProps={{ maxLength: 255 }}
        />
        
        <Button
          sx={{
            backgroundColor: '#E25614',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#FD784E',
            },
          }}
          variant="contained"
          size="small"
          onClick={() => {
            handleConfirmNewProject();
            setAnchorEl(null);
          }}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  </>
  );
};

export default observer(AddProject);
