import React, { FC } from 'react';
import { Modal as ModalMUI } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Paper } from './styles';
import { pxToRem } from '~/utils';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  width?: number | string;
};

const Modal: FC<Props> = ({ open, onClose, title, children, width = pxToRem(300) }) => {

  return (
    <ModalMUI
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: width,
            marginBottom: pxToRem(24),
          }}
        >
          <h3 style={{ color: '#FFFFFF' }}>{title}</h3>
          <Close style={{ cursor: 'pointer', color: 'white' }} onClick={onClose} />
        </div>
        <div>
          {children}
        </div>
      </Paper>
    </ModalMUI>
  );
};

export default Modal;
