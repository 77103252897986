import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { GeneratorAPI, IntegrationAlmAPI } from '~/services/api';
import store from '~/stores';
import FileSaver from 'file-saver';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import { alert } from '~/utils';


export default class StoryStore implements ItemStore {
  @observable
  issueType: 'Story' = 'Story';

  @observable
  selected?: Story.Type;

  @observable
  items: Story.Type[] = [];

  @observable
  generatedItems: Story.GeneratedItem[] = [];

  @observable isExportingToJira = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'StoryStore',
      properties: ['selected', 'items'],
      storage: window.localStorage,
    });
    this.saveItemsLocalStorage();
  }

  @action
  select(id: number): void {
    this.selected = this.items.find(item => item.id === id);
  };

  @action
  add(item: Story.Type): void {
    this.items.push(item);
  };

  @action
  edit(item: Story.Type): void {
    const index = this.items.findIndex(existingItem => existingItem.id === item.id);
    this.items[index] = item;
  };

  @action
  remove(id: number): void {
    this.items = this.items.filter(item => item.id !== id);
    this.selected = undefined;
  };

  @action
  resetItems(): void {
    this.items = [];
  };

  @action
  setFeedback = async (id: number, feedback: number): Promise<void> => {
    const index = this.items.findIndex(item => item.id === id);
    this.items[index].feedback = feedback;
    if (this.generatedItems.length > 0) {
      const indexGeneratedItem = this.generatedItems.findIndex(item => item.id === id);
      if (indexGeneratedItem !== -1) {
        this.generatedItems[indexGeneratedItem].feedback = feedback;
      }
    }
    await this.onStoryFeedback(this.items[index]);
  };

  @action
  onStory = async (data: Story.Forms): Promise<any> => {
    const rsp = await GeneratorAPI.onStory(data);
    return rsp;
  };

  @action
  getStory = async (data: StoryType.GetResponse): Promise<boolean> => {
    this.generatedItems = [];
    const rsp = await GeneratorAPI.getStory(data);
    if (rsp.length === 0) {
      return false;
    } else {
      rsp[0].content.map((item) => {
        let itemId = store.itemId.generate()
        this.add({
          id: itemId,
          artifact_id: rsp[0]._id,
          item_id: item.id,
          feedback: Number(item.feedback),
          content: {
            summary: item.summary,
            description: item.description ? item.description : '',
          },
          project: store.project.selected!,
          forms: {
            epic: rsp[0].title,
            description: rsp[0].description,
            business_rule: '',
            severity: '',
            amount: rsp[0].content.length,
          }
        });
        this.generatedItems.push({
          project: store.project.selected!,
          id: itemId,
          feedback: Number(item.feedback),
          content: {
            summary: item.summary,
            description: item.description ? item.description : '',
          }
        });
      });
      return true;
    };
  };

  @action
  updateStory = async (story: Story.Type): Promise<any> => {
    const rsp = await GeneratorAPI.updateStory(story);
    return rsp;
  };

  @action
  onStoryFeedback = async (story: Story.Type): Promise<any> => {
    const rsp = await GeneratorAPI.onStoryFeedback(story);
    return rsp;
  };

  @action
  saveItemsLocalStorage(): void {
    const storyStore = localStorage.getItem('StoryStore');
    if (storyStore) {
      const storyStoreJson = JSON.parse(storyStore);
      storyStoreJson.items = this.items;
      localStorage.setItem('StoryStore', JSON.stringify(storyStoreJson));
    }
  };

  @action
  exportToPDF = (): void => {
    try {
      const doc = new jsPDF();
      doc.setFontSize(12);

      const selectedProject = store.project.selected;
      const projectName = store.project.selected?.name || '';
      const projectId = selectedProject?.id;
      const date = new Date();
      const formattedDate = format(date, 'dd-MM-yyyy HH:mm:ss');

      const filteredItems = this.items.filter(item =>
        projectId ? item.project.id === projectId && item.feedback === 2 : false
      )

      if (filteredItems.length === 0) {
        doc.text("Nenhuma história encontrada.", 10, 10);
      } else {
        filteredItems.forEach((item, index) => {
          const startY = 10 + (index * 30);

          const summaryLines = doc.splitTextToSize(`${item.content.summary}`, 190);
          doc.setFont('helvetica', 'bold');
          doc.text(summaryLines, 10, startY + 8);
          const descriptionLines = doc.splitTextToSize(`Descrição: ${item.content.description}`, 190);
          doc.setFont('helvetica', 'normal');
          doc.text(descriptionLines, 10, startY + 8 + summaryLines.length * 8);
        });
      }

      const pdfFileName = `${projectName}_${formattedDate.replace(/[:\s]/g, '-')}.pdf`;
      doc.save(pdfFileName);

      alert({
        message: 'História exportada com sucesso!',
        type: 'success',
        position: 'top-center',
      });
    } catch (error) {
      alert({
        message: `Erro ao exportar história: ${(error as Error).message}`,
        type: 'error',
        position: 'top-center',
      });
    }
  };

  @action
  exportItemsToTxt(): void {
    const projectId = store.project.selected?.id;
    const content = this.items
      .filter(item => projectId ? item.project.id === projectId && item.feedback === 2 : false)
      .map(item => {
        if (item.content) {
          return `${item.content.summary}\nDescrição: ${item.content.description}`;
        } else {
          return "Conteúdo não disponível";
        }
      }).join('\n\n');

    const projectName = store.project.selected?.name || '';
    const date = new Date();
    const formattedDate = format(date, 'dd-MM-yyyy HH:mm:ss');

    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    try {
      FileSaver.saveAs(blob, `${projectName}_${formattedDate}.txt`);
      alert({
        message: 'História exportada com sucesso!',
        type: 'success',
        position: 'top-center',
      });
    } catch (error) {
      alert({
        message: `Erro ao exportar história: ${(error as Error).message}`,
        type: 'error',
        position: 'top-center',
      });
    }
  };

  @action
  exportToJira = async (): Promise<boolean> => {
    this.isExportingToJira = true;
    const issues: Jira.ExportRequest[] = this.items
      .filter(item => item.feedback === 2)
      .map(item => ({
        project: String(store.project.selected!.id),
        summary: item.content.summary,
        description: item.content.description,
        issuetype: {
          hierarchyLevel: 0,
          name: "História",
        },
      }));
    
    try{
      const response = await GeneratorAPI.exportItemsToJira({ issues });
      if (response.status === 201) {
        alert({
          message: 'Histórias exportadas para o Jira com sucesso!',
          type: 'success',
          position: 'top-center',
        });
        return true;
      } else {
        alert({
          message: `Erro ao exportar histórias para o Jira: ${response.status} | ${response.statusText}`,
          type: 'error',
          position: 'top-center',
        });
        return false;
      }
    } finally {
      this.isExportingToJira = false; 
    }
  };
};
