import React, { FC } from 'react';
import { Formik, observer, useStores, alert } from '~/utils';
import Story from './Story';

type Props = {
  response?: StoryType.Response[];
};

const StoryContainer: FC<Props> = () => {
  const { story } = useStores();

  const onSubmit = async (data: Story.Forms, { setSubmitting }: any) => {
    if (!data?.epic || !data?.description) {
      alert({
        message: 'Os campos épico e descrição são obrigatórios.',
        type: 'error',
        position: 'top-center',
      });
      return;
    }
    setSubmitting(true);

    const response = await story.onStory(data);
    if (response) {
      const resp = await story.getStory({ id: response });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        epic: '',
        description: '',
        business_rule: '',
        severity: '',
        amount: 0,
      }}
    >
      <Story />
    </Formik>
  );
};

export default observer(StoryContainer);
