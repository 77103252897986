import React, { FC } from 'react';
import { Formik, observer, useStores, alert } from '~/utils';
import TestCase from './Tests';

type Props = {
  response?: TestsType.Response[];
};

const TestsContainer: FC<Props> = () => {
  const { testCase } = useStores();

  const onSubmit = async (data: TestsType.Forms, { setSubmitting }: any) => {
    if (!data?.system || !data?.functionality || !data?.acceptanceCriteria) {
      alert({
        message: 'Os campos sistema, funcionalidade e critérios de aceitação são obrigatórios.',
        type: 'error',
        position: 'top-center',
      });
      return;
    }
    setSubmitting(true);

    const response = await testCase.onTestCase(data);
    if (response) {
      const resp = await testCase.getTestCase({ id: response });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        system: '',
        epic: '',
        functionality: '',
        rules: '',
        userStory: '',
        acceptanceCriteria: '',
        denialCriteria: '',
        amount: 0,
      }}
    >
      <TestCase />
    </Formik>
  );
};

export default observer(TestsContainer);
