import { observable, action, makeObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class ItemIdStore {
  @observable
  currentId: number = 0;

  constructor() {
    makeObservable(this);
    makePersistable(this, {
        name: 'Item Id',
        properties: ['currentId'],
        storage: window.localStorage,
      }
    );
  }

  @action
  generate(): number {
    this.currentId = this.currentId + 1;
    return this.currentId;
  };
  
  @action
  reset(): void {
    this.currentId = 0;
  };
};
