const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_HEALTH_BEAT_API_URL,
  REACT_APP_API_EPIC_GEN,
  REACT_APP_API_STORY_GEN,
  REACT_APP_API_TEST_CASE_GEN,
  REACT_APP_API_INTEGRATION_ALM_GEN,
  REACT_APP_X_TOKEN,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_PRIME_SSO_URL,
  REACT_APP_PRIME_SSO_CONFIG_URL,
  REACT_APP_DOMAIN,
} = process.env;
export {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_HEALTH_BEAT_API_URL,
  REACT_APP_API_EPIC_GEN,
  REACT_APP_API_STORY_GEN,
  REACT_APP_API_TEST_CASE_GEN,
  REACT_APP_API_INTEGRATION_ALM_GEN,
  REACT_APP_X_TOKEN,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_PRIME_SSO_URL,
  REACT_APP_PRIME_SSO_CONFIG_URL,
  REACT_APP_DOMAIN,
};
