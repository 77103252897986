import { getTheme, pxToRem, styled } from '~/utils';

// Breakpoints
const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');

export const Paper = styled.div`
  color: white;
  background-color: #081E38;
  padding: ${pxToRem(30)};
  border-radius: ${pxToRem(8)};
  box-shadow: 0px 0px 5px 0px #000000;

  @media ${inTablet} {
    left: 30%;
  }

  @media ${inMobile} {
    left: 20%;
  }
`;
