import React, { FC } from 'react';
import { Doc, Epic, History, Resource, Tests } from '~/assets/svg';
import Arrow from '~/assets/svg/Arrow';
import { colors } from '~/theme';
import { Content, Description, TextContainer, Title } from './styles';

type Props = {
  data: any;
  onPress: () => void;
  disabled?: boolean;
};

const icons = {
  epic: Epic,
  resource: Resource,
  history: History,
  doc: Doc,
  tests: Tests,
};

const color = {
  epic: colors.primary.main,
  resource: colors.secondary.main,
  history: colors.tertiary.main,
  doc: colors.accent.main,
  tests: colors.secondary.dark,
};

const CardResource: FC<Props> = ({ onPress, data, disabled }) => {
  const LimitedText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  return (
    <Content
      onClick={onPress}
      style={{
        backgroundColor: disabled ? 'gray' : undefined,
      }}
    >
      <TextContainer>
        <Title>{data.key}</Title>
        <Description>{LimitedText(data.summary, 70)}</Description>
      </TextContainer>
    </Content>
  );
};

export default CardResource;
