import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Heading = styled(Typography)`
  font-size: 0.9375rem; 
  font-weight: 400; 
`;

export const ThumbContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
`;
