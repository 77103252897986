import React, { FC } from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const Icon: FC<Props> = ({ width = 24, height = 24, color = "#F0F8FF" }) => (
  <svg width={width} height={height} viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" fill={color} />
  </svg>
);

export default Icon;
