import { getTheme, pxToRem, styled } from '~/utils';

// Colors
const primaryLight = getTheme('primary.light');
const primaryContrast = getTheme('primary.contrast');

// Spacing
const sceneSpacing = getTheme('sceneSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');

// Query
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${primaryLight};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${mediumSpacing} ${sceneSpacing};
  background-color: ${primaryLight};

  @media ${inMobileAndTablet} {
    padding: ${smallSpacing};
  }
`;

export const SectionFields = styled.div`
  margin-top: ${pxToRem(25)};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${primaryContrast};
  margin-bottom: ${pxToRem(40)};
  padding-bottom: ${pxToRem(40)};
`;

export const AlignFields = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
`;

export const WrapperFields = styled.div`
  width: 100%;
  display: flex;

  @media ${inMobileAndTablet} {
    flex-direction: column;
  }
`;
