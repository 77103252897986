import { REACT_APP_X_TOKEN } from '~/utils';
import { Storage, Amplify } from '~/services';


type CustomRequest = {
  authorization?: string;
  };
  
export const interceptorsAuthHeaders = async (
  config: any,
): Promise<any & CustomRequest> => {
  const amplify = new Amplify();
  return {
    ...config,
    headers: {
      ...config.headers,
      'x-api-key': REACT_APP_X_TOKEN,
      authorization: await amplify.getToken(),
    },
  };
};
