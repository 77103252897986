import { pxToRem, styled } from '~/utils';

export const Content = styled.span`
  background-color: #183E62;
  width: ${pxToRem(80)};
  height: calc(100vh - ${pxToRem(80)});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  position: fixed;
  top: ${pxToRem(80)};
  left: 0;
  box-shadow: 0px 0px 20px 0px #081E38;
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const ItemButton = styled.button<{ border?: boolean; selected?: boolean }>`
  width: 3.5rem;
  height: 3.5rem;
  min-height: ${pxToRem(40)};
  margin-bottom: ${pxToRem(16)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ selected }) => (selected ? 'rgba(245, 130, 32, 0.5)' : 'none')};
  border: ${({ border }) => (border ? 'none' : '2px solid #081E38')};
  color: inherit;
  cursor: pointer;
  border-radius: 18%;
  

  &:hover {
    background-color: #F58220;
     transform: scale(0.95);

  }

  &:focus {
    outline: none;
   
  }
  
`;
