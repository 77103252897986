import React, { FC, useEffect, useState } from 'react';
import { REACT_APP_API_URL } from '~/utils';
import { Menu, MenuItem } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { PrimeLogon } from '~/assets/img';
import { Path } from '~/routes/routes.path';
import { Expand } from '~/assets/svg';
import { device } from '~/theme/breakpoints';
import { useStores, REACT_APP_ENV, REACT_APP_PRIME_SSO_URL } from '~/utils';
import If from '../If';
import { observer } from 'mobx-react';
import { Amplify } from '~/services';
import {
  Content,
  SectionIcon,
  ImgIcon,
  SectionLogo,
  SectionToolbar,
  NameUser,
  TextProduct,
} from './styles';
import { cleanTenantCookie } from '~/utils/tenants';

type Props = {
  title: string;
};

const amplify = new Amplify();

const Header: FC<Props> = ({ title }) => {
  const { routing } = useStores();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [username, setUsername] = useState('');
  const [tenantName, setTenantName] = useState('');

  useEffect(() => {
    const tenantNameFromCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('tenant_name='))
      ?.split('=')[1];
    if (tenantNameFromCookie) {
      setTenantName(tenantNameFromCookie);
    }
    const getUsername = async () => {
      if (REACT_APP_ENV === 'LOCAL') {
        setUsername('Ambiente Local');
      }else{
        const { username } = await amplify.getUsername();
        setUsername(username);
      }
    };
    getUsername();
  }, [username]);

  const onLogout = async () => {
    await amplify.logout();
    cleanTenantCookie();
    routing.replace(Path.LOGIN);
  };

  const onBackToSelection = () => {
    cleanTenantCookie();
    window.location.href = `${REACT_APP_PRIME_SSO_URL}/select-group`;
  };

  const onHome = () => {
    window.location.href = REACT_APP_PRIME_SSO_URL!;
  };

  return (
    <Content>
      <SectionLogo>
        <ImgIcon src={PrimeLogon} title='Home' onClick={onHome}/>
        <TextProduct>{title}</TextProduct>
      </SectionLogo>
      <SectionToolbar>
        <SectionIcon onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget as unknown as HTMLButtonElement)}>
          <If condition={!device.isMobile}>
            <NameUser>{username}</NameUser>
          </If>
          <Expand />
        </SectionIcon>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ marginTop: '4px' }}
        >
          {tenantName && (
            <MenuItem
              style={{ justifyContent: 'center' }}
              onClick={() => {
                onBackToSelection();
                setAnchorEl(null);
            }}>
              Trocar Workspace
            </MenuItem>
          )}
          <MenuItem
            style={{ justifyContent: 'center' }}
            onClick={() => {
              onLogout();
              setAnchorEl(null);
          }}>
            Sair
            <ExitToApp style={{ marginLeft: '8px' }} />
          </MenuItem>
        </Menu>
      </SectionToolbar>
    </Content>
  );
};

export default observer(Header);
