import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Epic, Story, Resource, Tests, Home } from '~/scenes';
import PrivateRoute from './private.route';
import RouterProvider from './provider';
import { Path } from './routes.path';
import { observer, useStores, REACT_APP_PRIME_SSO_URL } from '~/utils';
import { getTenantIdCookie } from '~/utils/tenants';

const SSO_LOGIN_URL = REACT_APP_PRIME_SSO_URL;

const NavigationApp: FC = observer(() => {
  const { project, itemId } = useStores();

  useEffect(() => {
    const cookieTenantId = getTenantIdCookie();
    const localStorageTenantId = localStorage.getItem('tenant_id');
    if (localStorageTenantId !== cookieTenantId) {
      localStorage.setItem('tenant_id', cookieTenantId);
      project.resetItems();
      project.selected = undefined;
      itemId.reset();
    }
  }, []);

  const LoginRedirect = () => {
    // Configurar página de erro se variável não estiver configurada
    if (SSO_LOGIN_URL != undefined) {
      window.location.href = `${SSO_LOGIN_URL}/login`;
      return null;
    }
  };

  return (
    <Routes>
      <Route path={Path.HOME} 
        element={<PrivateRoute render={<Home />} />}
      />
      <Route path={Path.EPIC} element={<PrivateRoute render={<Epic />} />} />
      <Route
        path={Path.RESOURCE}
        element={<PrivateRoute render={<Resource />} />}
      />
      <Route
        path={Path.STORY}
        element={<PrivateRoute render={<Story />} />}
      />
      <Route path={Path.TESTS} element={<PrivateRoute render={<Tests />} />} />
      <Route path={Path.LOGIN} Component={LoginRedirect} />
    </Routes>
  );
});

export { RouterProvider };

export default NavigationApp;

