import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class JiraImportStore {
  @observable
  items_import: JiraType.ImportItensForms[] = [];

  @observable
  selectMode: boolean = false;

  @observable
  editMode: boolean = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'JiraStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  get selectedCount() {
    return this.items_import.length;
  }

  @action
  toggleSelectMode() {
    this.selectMode = true;
    if (this.items_import.length === 0) {
      this.selectMode = false;
    }
  }

  @action
  toggleEditMode(selected: boolean) {
    this.editMode = selected;
    this.items_import = [];
  }

  @action
  addItemsImport = (data: any) => {
    this.items_import.push(data);
  }

  @action
  removeItemsImport = (data: any) => {
    this.items_import = this.items_import.filter(item => item !== data);
  }
}
