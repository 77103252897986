import Axios from 'axios';
import { interceptorsAuthHeaders } from './interceptors';

export const createRequest = (baseURL: string) => {
  const request = Axios.create({
    baseURL,
    timeout: 150000, // 15 seconds timeout
  });

  request.interceptors.request.use(interceptorsAuthHeaders);

  return request;
};

export default createRequest;
 