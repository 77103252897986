import ReactLoading from 'react-loading';
import { Button, Menu, MenuItem, TextField } from '@material-ui/core';
import { getTheme, pxToRem, styled } from '~/utils';

// Colors
const primaryMain = getTheme('primary.main');
const primaryContrast = getTheme('primary.contrast');
const primaryLight = getTheme('primary.light');
const primaryDark = getTheme('primary.dark');
const secondaryDark = getTheme('secondary.dark');

// Spacing
const smallSpacing = getTheme('smallSpacing');

// Media Queries
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const AlignFields = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
`;

export const Background = styled.div`
    display: flex;
    background-color: ${primaryDark};
    top: ${pxToRem(80)};
    left: ${pxToRem(80)};
    width: calc(100vw - ${pxToRem(80)});
    height: calc(100vh - ${pxToRem(80)});
    position: relative;
`;

export const Board = styled.div`
  width: 75vw;
  min-width: ${pxToRem(400)};
  display: flex;
  flex-direction: column;  
  padding: ${smallSpacing};
  background-color: ${primaryDark};
`;

export const ButtonBox = styled.span`
  display: flex;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  margin: ${pxToRem(8)} ${pxToRem(16)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardItemsSpace = styled.div<{ items: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #183E62;
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(16)} ${pxToRem(16)} 0 ${pxToRem(16)};
  max-height: 60vh;
  ${props => props.items && `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: ${pxToRem(10)};
      padding: ${pxToRem(4)};
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: ${pxToRem(8)};
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: ${pxToRem(8)};
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
`;

export const CustomTextField = styled(TextField)`
  & label {
    font-size: 0.9em;
    color: #fff;
  }
  ::placeholder {
    font-size: 0.9em;
  }
  & .MuiInputBase-root {
    background-color: ${secondaryDark};
    color: #fff;  
  }
  & .MuiOutlinedInput-root {
    color: #fff;
  }
  & label.Mui-focused {
    color: ${primaryMain};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${primaryMain};
    }
  }
`;

export const GeneratedItemsSpace = styled.div<{ items: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #183E62;
  border-radius: ${pxToRem(8)};
  padding: 16px;
  color: white;
  max-height: 40vh;
  ${props => props.items && `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: ${pxToRem(10)};
      padding: ${pxToRem(4)};
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: ${pxToRem(8)};
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: ${pxToRem(8)};
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
`;

export const MoreButtonOnCard = styled.button`
  border: none;
  border-radius: ${pxToRem(4)};
  background-color: transparent;
  &:hover {
    background-color: #28537c;
  }
  margin-top: ${pxToRem(4)};
  padding: 2px 2px 0 2px;
`;

export const MoreButtonOnProject = styled.button`
  border: none;
  border-radius: ${pxToRem(4)};
    background-color: transparent;
  &:hover {
    background-color: #183E62;
  }
  margin-right: ${pxToRem(24)};
  padding: 2px 2px 0 2px;
`;

export const Panel = styled.div`
  width: 25vw;
  min-width: ${pxToRem(300)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${smallSpacing};
`;

export const SectionFields = styled.div`
  margin-top: ${pxToRem(4)};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${primaryContrast};
  margin-bottom: ${pxToRem(16)};
  padding-bottom: ${pxToRem(16)};
`;

export const StyledButton = styled(Button)`
  width: ${pxToRem(150)};
  background-color: #1880A0 !important;
  &:hover {
    background-color: #026D8E !important;
  }
  &:disabled {
    opacity: 0.5;
    color: #373737 !important;
    background-color: #8A8A8A !important;
    box-shadow: none;
  }

  @media (min-width: 1025px) and (max-width: 1366px) {
    width: ${pxToRem(105)};
  }

  @media ${inMobileAndTablet} {
    width: ${pxToRem(100)};
    font-size: ${pxToRem(12)} !important;
  }
`;

export const StyledMenu = styled(Menu)`
  margin-top: ${pxToRem(8)};
  .css-6hp17o-MuiList-root-MuiMenu-list {
    color: white;
  }
  .css-edx3xz-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    background-color: #183e62;
    box-shadow: 0px 0px ${pxToRem(5)} 0px #000000;
  }
`;

export const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  font-size: ${pxToRem(14)} !important;
  ${props => props.selected && `
    border: ${pxToRem(1)} solid #FD784E !important;
  `}
  &:focus {
    background-color: none;
  }
  &:hover {
    background-color: #FD784E !important;
  }
  @media ${inMobileAndTablet} {
    font-size: ${pxToRem(12)};
  }
`;

export const StyledReactLoading = styled(ReactLoading)`
  flex: 1;
`;

export const TextEmpty = styled.p`
  text-align: center;
  color: #696969;
  font-weight: bold;
`;

export const Title = styled.h3`
  margin-top: ${pxToRem(8)};
  margin-left: ${pxToRem(8)};
  color: #f2f2f2;
`

export const ToggleContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${pxToRem(8)};
  align-content: center;
  cursor: pointer;
  width: 50%;
`;

export const ToggleText = styled.p`
  text-align: left;
  font-size: 1em;
  color: ${primaryLight};
  display: flex;
  align-items: center;
`;

export const VerticalDivisor = styled.div`
  box-sizing: border-box;
  width: ${pxToRem(1)};
  min-width: ${pxToRem(1)};
  height: calc(100vh - ${pxToRem(96)});
  background-color: #535353;
  margin: ${smallSpacing};
`;

export const WrapperFields = styled.div`
  width: 100%;
  display: flex;

  @media ${inMobileAndTablet} {
    flex-direction: column;
  }
`;
