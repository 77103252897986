import React, { FC } from 'react';
import { Logo } from '~/assets/svg';
import { Container, Img, Paragraph, Title } from './styles';

const Home: FC = (): JSX.Element => {

  return (
    <Container>
      <Img src={Logo} />
      <Title>Prime AI</Title>
      <Paragraph>Sua IA para Épicos, Estórias e Códigos.</Paragraph>
      <a 
        style={{ 
          textDecoration: 'none',
          color: '#FFF',
          fontSize: '25px',
        }}
        href="/epic">
        EpicGen
      </a>
      <a 
        style={{ 
          textDecoration: 'none',
          color: '#FFF',
          fontSize: '25px',
        }}
        href="/story">
        StoryGen
      </a>
      <a 
        style={{ 
          textDecoration: 'none',
          color: '#FFF',
          fontSize: '25px',
        }}
        href="/tests">
        TestCaseGen
      </a>
    </Container>
  );
};

export default Home;
