import Axios, { AxiosResponse } from 'axios';
import { Request } from '~/services';
import { alert, REACT_APP_API_EPIC_GEN, REACT_APP_API_STORY_GEN, REACT_APP_API_INTEGRATION_ALM_GEN, REACT_APP_API_TEST_CASE_GEN } from '~/utils';
import { getTenantIdCookie } from '~/utils/tenants';

let tenantId = getTenantIdCookie();

export const onTestCase = async ({
  system,
  epic,
  functionality,
  rules,
  userStory,
  acceptanceCriteria,
  denialCriteria,
  amount,
}: TestsType.Forms): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_TEST_CASE_GEN || "").post('/test-case', {
      system: system,
      epic: epic,
      functionality: functionality,
      rules: rules,
      user_story: userStory,
      acceptance_criteria: acceptanceCriteria,
      denial_criteria: denialCriteria,
      amount: amount,
    });
    return response.data;    
  } catch ( error: any ) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
};

export const getCaseTest = async ({
  id,
}: TestsType.GetResponse): Promise<TestsType.Response[]> => {
  const delayBetweenRetries = 3000;
  const maxAttempts = 10;
  let response;
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      response = await Request(REACT_APP_API_TEST_CASE_GEN || "").get(`/test-case/${id}`);
      if (response.data.content && !response.data.error) {
        return typeof response.data === 'object' ? [response.data] : response.data;
      } else if (response.data.error) {
        alert({
          message: response.data.error,
          type: 'error', 
          position: 'top-center'
        });
        return [];
      }
    } catch (error: any) {
      if (error.response.status === 503) {
        alert({
          message: error.response.data.error,
          type: 'error', 
          position: 'top-center'
        });
      } else {
        alert({
          message: error.message,
          type: 'error', 
          position: 'top-center'
        });
      }
      return [];
    }
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, delayBetweenRetries));
  }
  alert({
    message: 'Não foi possível obter os dados dos casos de teste.',
    type: 'error',
    position: 'top-center'
  });
  return [];
};

export const updateTestCase = async (testCase: TestCase.Type): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_TEST_CASE_GEN || "").put(`/test-case/${testCase.artifact_id}/${testCase.item_id}`, { 
      id: testCase.id,
      summary: testCase.content.summary,
      description: testCase.content.description,
      scenery: testCase.content.scenary,
      feedback: testCase.feedback,
      alm_id: testCase.artifact_id,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
}

export const onTestCaseFeedback = async (testCase: TestCase.Type): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_TEST_CASE_GEN || "").patch(`/test-case/${testCase.artifact_id}/${testCase.item_id}/feedback`, testCase.feedback);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
}

export const onStory = async ({
  epic,
  description,
  business_rule,
  severity,
  amount,
}: Story.Forms): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_STORY_GEN || "").post('/story', {
      epic: epic,
      description: description,
      business_rule: business_rule,
      severity: severity,
      amount: amount,
    });
    return response.data;
  } catch ( error: any ) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
};

export const getStory = async ({
  id,
}: StoryType.GetResponse): Promise<StoryType.Response[]> => {
  const delayBetweenRetries = 3000;
  const maxAttempts = 10;
  let response;
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      response = await Request(REACT_APP_API_STORY_GEN || "").get(`/story/${id}`);
      if (response.data.content && !response.data.error) {
        return typeof response.data === 'object' ? [response.data] : response.data;
      } else if (response.data.error) {
        alert({
          message: response.data.error,
          type: 'error', 
          position: 'top-center'
        });
        return [];
      }
    } catch (error: any) {
      if (error.response.status === 503) {
        alert({
          message: error.response.data.error,
          type: 'error', 
          position: 'top-center'
        });
      } else {
        alert({
          message: error.message,
          type: 'error', 
          position: 'top-center'
        });
      }
      return [];
    }
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, delayBetweenRetries));
  };
  alert({
    message: 'Não foi possível obter os dados das histórias.',
    type: 'error',
    position: 'top-center'
  });
  return [];
};

export const updateStory = async (story: Story.Type): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_STORY_GEN || "").put(`/story/${story.artifact_id}/${story.item_id}`, { 
      id: story.item_id,
      summary: story.content.summary,
      description: story.content.description,
      acceptance_criteria: story.content.acceptanceCriteria,
      story_points: story.content.storyPoints,
      feedback: story.feedback,
      alm_id: story.artifact_id,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
}

export const onStoryFeedback = async (story: Story.Type): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_STORY_GEN || "").patch(`/story/${story.artifact_id}/${story.item_id}/feedback`, story.feedback);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
}

export const getEpic = async ({
  id,
}: EpicType.GetResponse): Promise<EpicType.Response[]> => {
  const delayBetweenRetries = 3000;
  const maxAttempts = 10;
  let response;
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      response = await Request(REACT_APP_API_EPIC_GEN || "").get(`/epic/${id}`);
      if (response.data.content && !response.data.error) {
        return typeof response.data === 'object' ? [response.data] : response.data;
      } else if (response.data.error) {
        alert({
          message: response.data.error,
          type: 'error', 
          position: 'top-center'
        });
        return [];
      }
    } catch (error: any) {
      if (error.response.status === 503) {
        alert({
          message: error.response.data.error,
          type: 'error', 
          position: 'top-center'
        });
      } else {
        alert({
          message: error.message,
          type: 'error', 
          position: 'top-center'
        });
      }
      return [];
    }
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, delayBetweenRetries));
  };
  alert({
    message: 'Não foi possível obter os dados dos épicos.',
    type: 'error',
    position: 'top-center'
  });
  return [];
};

export const onEpic = async ({
  objective,
  application,
  amount,
}: Epic.Forms): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_EPIC_GEN || "").post('/epic', {
      application: application,
      objective: objective,
      amount: amount,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
};

export const updateEpic = async (epic: Epic.Type): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_EPIC_GEN || "").put(`/epic/${epic.artifact_id}/${epic.item_id}`, { 
      id: epic.item_id,
      summary: epic.content.summary,
      description: epic.content.description,
      feedback: epic.feedback,
      alm_id: epic.artifact_id  
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
}

export const onEpicFeedback = async (epic: Epic.Type): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_EPIC_GEN || "").patch(`/epic/${epic.artifact_id}/${epic.item_id}/feedback`, epic.feedback);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'top-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'top-center'
      });
    }
    return false;
  }
}

export const exportItemsToJira = async ({
  issues,
}: { issues: Jira.ExportRequest[] }): Promise<AxiosResponse> => {
  try {
    const response = await Request(REACT_APP_API_INTEGRATION_ALM_GEN || "").post(`/projects/${tenantId}/issues`, issues);
    return response;
  } catch (error: any) {
    return {
      data: { error: error.response?.data?.error || error.message },
      status: error.response?.status || 500,
      statusText: error.response?.statusText || 'Internal Server Error',
      headers: error.response?.headers || {},
      config: error.config,
      request: error.request,
    } as AxiosResponse;
    }
};