import React, { FC, useEffect, useState } from 'react';
import { Button, IconButton, MenuItem, Tooltip, Switch, Box, Menu } from '@material-ui/core';
import { Check, Close, GetApp, Publish } from '@material-ui/icons';
import { Play, Jira, More } from '~/assets/svg';
import {
  CardAccordion,
  DropDown,
  Header,
  If,
  Menu as SideMenu,
  Modal,
} from '~/components';
import {
  AlignFields,
  Background,
  Board,
  ButtonBox,
  ButtonsContainer,
  CardItemsSpace,
  CustomTextField,
  GeneratedItemsSpace,
  MoreButtonOnCard,
  MoreButtonOnProject,
  Panel,
  SectionFields,
  StyledButton,
  StyledReactLoading,
  TextEmpty,
  Title,
  ToggleContent,
  ToggleText,
  VerticalDivisor,
  WrapperFields,
} from '~/styles';
import { colors } from '~/theme';
import { observer, alert, useStores, useFormikContext, REACT_APP_HEALTH_BEAT_API_URL, REACT_APP_ENV } from '~/utils';
import { Request } from '~/services';
import { getTenantIdCookie } from '~/utils/tenants';
import ReactLoading from 'react-loading';

type Props = {};

const TestCase: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<TestsType.Forms>(); 
  const { testCase, project, epic } = useStores();
  const [autoAmount, setAutoAmount] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState<null | number>(null);
  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null);
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(null);
  const [anchorElCard, setAnchorElCard] = useState<null | HTMLElement>(null);
  const [anchorElProject, setAnchorElProject] = useState<null | HTMLElement>(null);
  const [editCard, setEditCard] = useState(false);
  const [editedTestCase, setEditedTestCase] = useState<any>(null); 
  const [isEditing, setIsEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(project.selected?.name || '');
  const [loadingJira, setLoadingJira] = useState(false);

  useEffect(() => {
    project.getJiraProjects();
    project.orderProjects();
    if(REACT_APP_ENV === 'PRD') {
      const healthCheck = async () => {
        try {
          const tenantId = getTenantIdCookie();
          const response = await Request(REACT_APP_HEALTH_BEAT_API_URL || "")
          .get(`/heart-beat/${tenantId}`, {
            headers: {
            'app': 'TestCaseGen',
            },
          });
        } catch (error) {
          console.log('Erro ao tentar fazer health check:', error);
        }
      };

      const interval = setInterval(() => {
        if (document.visibilityState === 'visible') {
          healthCheck();
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const hasGeneratedItemsForSelectedProject = () => {
    return testCase.generatedItems.length > 0 && testCase.generatedItems.some((item) => item.project.id === project.selected?.id);
  };

  const hasLikedTestCasesForSelectedProject = () => {
    return testCase.items.some(
      (item) => item.feedback === 2 && item.project.id === project.selected?.id,
    );
  };

  const defineAmount = () => {
    if (!autoAmount) {
      values.amount = 1;
      alert({
        message: 'A quantidade de casos de teste gerados será definida manualmente',
        type: 'info',
        position: 'top-center',
      });
    } else {
      values.amount = 0;
      alert({
        message: 'A quantidade de casos de teste gerados será definida automaticamente',
        type: 'info',
        position: 'top-center',
      });
    }
    setAutoAmount(!autoAmount);
  };

  const handleSaveClick = () => {
    if (project.selected) {
      if(!editedProjectName.trim()) {
        alert({ message: 'Nome do projeto não pode ser vazio', type: 'warning', position: 'top-center' });
      }else{
        project.selected.name = editedProjectName;
      }
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedProjectName(project.selected?.name || '');
    setIsEditing(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: { key: string; }) => {
      if (isEditing) {
        if (event.key === 'Enter') {
          handleSaveClick();
        } else if (event.key === 'Escape') {
          handleCancelClick();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, handleSaveClick, handleCancelClick]);

  const handleEditTestCase = (item: TestCase.Type) => {
    setEditedTestCase(item); 
    setEditCard(true); 
  };

  const handleUpdateTestCase = async () => {
    if (!editedTestCase?.content?.summary || !editedTestCase?.content?.description) {
      alert({
        message: 'O título e a descrição do caso de teste são obrigatórios.',
        type: 'error',
        position: 'top-center',
      });
      return;
    }
  
    const payload = {
      id: editedTestCase.id,
      artifact_id: editedTestCase.artifact_id,
      item_id: editedTestCase.item_id,
      content: {
        summary: editedTestCase.content.summary,
        description: editedTestCase.content.description,
      },
      feedback: editedTestCase.feedback, // Inclua o feedback, se necessário
      project: editedTestCase.project, // Certifique-se de que o projeto está correto
    };
  
    testCase.edit(payload); 
  
    try {
      await testCase.updateTestCase(payload); // Atualiza o caso de teste com o payload completo
      setEditCard(false); // Fecha o modal de edição
    } catch (error) {
      console.error('Erro ao tentar salvar o caso de teste:', error);
    }
  };

  return (
    <>
      <Header title="TestCaseGen" />
      <SideMenu />
      <Background>
        <Panel>
          <DropDown
            type="project"
            onClick={() => {
              setFieldValue('system', '');
              setFieldValue('epic', '');
              setFieldValue('functionality', '');
              setFieldValue('rules', '');
              setFieldValue('userStory', '');
              setFieldValue('acceptanceCriteria', '');
              setFieldValue('denialCriteria', '');
              setFieldValue('amount', 0);
            }}
          />
          <DropDown
            type="epic"
            onClick={() => {
              setFieldValue('epic', epic.selected?.content.summary || '');
              setFieldValue('description', epic.selected?.content.description || '');
              setFieldValue('business_rule', '');
              setFieldValue('amount', 0);
            }}
          />
          {/* Modal para edição de casos de teste */}
          <Modal
            title="Editar Caso de Teste"
            open={editCard}
            onClose={() => {
              setEditCard(false);
              setAnchorElCard(null);
            }}
            width={600}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '8px 0',
              }}
            >
              <CustomTextField
                label="Título do Caso de Teste"
                placeholder="Digite o título do caso de teste"
                variant="outlined"
                size="small"
                value={editedTestCase?.content?.summary || ''} 
                onChange={(event) =>
                  setEditedTestCase({
                    ...editedTestCase,
                    content: { ...editedTestCase.content, summary: event.target.value }, 
                  })
                }
                style={{ marginBottom: '20px' }}
                inputProps={{ maxLength: 255 }}
              />
              
              <CustomTextField
                label="Descrição"
                placeholder="Digite a descrição do caso de teste"
                variant="outlined"
                size="small"
                multiline
                value={editedTestCase?.content?.description || ''} 
                onChange={(event) =>
                  setEditedTestCase({
                    ...editedTestCase,
                    content: { ...editedTestCase.content, description: event.target.value }, 
                  })
                }
                style={{ marginBottom: '20px' }}
                inputProps={{ maxLength: 1000 }}
              />
                <div style={{ marginTop: '8px', textAlign: 'right', fontSize: '12px', color: 'gray' }}>
                    {editedTestCase?.content?.description?.length || 0}/1000 caracteres
                </div>
              <Button
                sx={{
                  backgroundColor: '#E25614',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#FD784E',
                  },
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  handleUpdateTestCase();
                  setEditCard(false);
                  setAnchorElCard(null);
                }} 
                style={{ marginTop: '16px' }}
              >
                Confirmar
              </Button>
            </div>
          </Modal>
          <div
            style={{
              width: '85%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >
              <StyledButton
                startIcon={<GetApp />}
                variant="contained"
                size="small"
                style={{ marginBottom: '16px' }}
                disabled={!hasLikedTestCasesForSelectedProject()}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorElExport(event.currentTarget)}
              >
                Exportar
              </StyledButton>
              <Menu
                open={Boolean(anchorElExport)}
                onClose={() => setAnchorElExport(null)}
                anchorEl={anchorElExport}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                style={{ marginTop: '4px' }}
                PaperProps={{
                  style: {
                    width: anchorElExport ? anchorElExport.clientWidth : 'auto',
                    minWidth: '100px',
                  },
                }}
              >
                <MenuItem
                  style={{ justifyContent: 'center' }}
                  onClick={() => testCase.exportItemsToTxt()}
                  >
                  TXT
                </MenuItem>
                <MenuItem
                  style={{ justifyContent: 'center' }}
                  onClick={() => {
                    testCase.exportToPDF();
                    setAnchorElExport(null);
                  }}
                >
                  PDF
                </MenuItem>
                {project.selected?.type === 'jira' && (
                <MenuItem
                style={{ justifyContent: 'center' }}
                onClick={() => testCase.exportToJira()}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                  {testCase.isExportingToJira ? (
                    <ReactLoading type="spin" color="#000000DE" height={16} width={16} />
                  ) : (
                    <>
                    <Jira color="#000000DE" size={16} style={{ marginRight: '8px' }} />
                    Jira
                    </>
                  )}
                  </span>
                </MenuItem>
                )}
              </Menu>
            <CardItemsSpace items={hasLikedTestCasesForSelectedProject()}>
              {!hasLikedTestCasesForSelectedProject() ? (
                <TextEmpty style={{ paddingBottom: '16px' }}>Os casos de teste escolhidos aparecerão aqui</TextEmpty>
              ) : (
                <>
                  {testCase.items
                    .filter((item: any) =>
                      project.selected
                        ? item.project.id === project.selected.id
                        : '',
                    )
                    .filter((item) => item.feedback === 2)
                    .map((item: any) => (
                      <Box
                      key={item.id}
                        sx={{
                          width: '100%',
                          borderRadius: 1,
                          marginBottom: '16px',
                          bgcolor: '#43688B',
                          textAlign: 'center',
                          padding: '24px 16px 16px 16px',
                          cursor: 'pointer',
                          color: 'white',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                          }}
                        >
                          {item.content.summary}
                        </p>
                        <small
                          style={{
                            display: '-webkit-box',
                            width: '100%',
                            fontSize: '12px',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: '8px',
                            whiteSpace: 'pre-line',
                            textAlign: 'left',
                          }}
                        >
                            {item.content.description}
                        </small>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                            position: 'relative',
                          }}
                        >
                          <MoreButtonOnCard
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              setAnchorElCard(event.currentTarget);
                              setSelectedTestId(item.id);
                            }}
                          >
                            <More />
                          </MoreButtonOnCard>
                          <Menu
                            open={Boolean(anchorElCard) && selectedTestId === item.id}
                            onClose={() => {
                              setAnchorElCard(null);
                              setSelectedTestId(null);
                            }}
                            anchorEl={anchorElCard}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            style={{ marginTop: '4px' }}
                            PaperProps={{
                              style: {
                                width: anchorElCard ? anchorElCard.clientWidth : 'auto',
                                minWidth: '100px',
                              },
                            }}
                          >
                            <MenuItem
                              style={{ justifyContent: 'center' }}
                              onClick={() => handleEditTestCase(item)} 
                            >
                              Editar
                            </MenuItem>
                            <MenuItem
                              style={{ justifyContent: 'center' }}
                              onClick={() => {
                                testCase.setFeedback(item.id, 3);
                                setAnchorElCard(null)
                              }}
                            >
                              Excluir
                            </MenuItem>
                          </Menu>
                        </div>
                      </Box>
                    ))}
                </>
              )}
            </CardItemsSpace>
          </div>
        </Panel>
        <VerticalDivisor />
        <Board>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title>
              {isEditing ? (
                  <CustomTextField
                    value={editedProjectName} focused
                    label="Nome do Projeto"
                    placeholder="Digite o nome do projeto"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setEditedProjectName(e.target.value)}
                    inputProps={{ maxLength: 255 }}
                  />
                ) : (
                  <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                    {project.selected?.name}
                  </span>
                )}
                {!isEditing && (
                  <span onClick={() => setIsEditing(true)} style={{ marginLeft: 8 }}>
                  </span>
                )}
                {isEditing && (
                  <>
                    <IconButton onClick={handleSaveClick}>
                      <Check style={{ color: 'white' }}/>
                    </IconButton>
                    <IconButton onClick={handleCancelClick}>
                      <Close style={{ color: 'white' }}/>
                    </IconButton>
                  </>)}
                </Title>
              <div
                style={{ display: 'inline-block', position: 'relative' }}
              >
                {project.selected && project.selected.type !== 'jira' && (
                  <>
                    <MoreButtonOnProject onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorElProject(event.currentTarget)}>
                      <More />
                    </MoreButtonOnProject>
                    <Menu
                      open={Boolean(anchorElProject)}
                      onClose={() => setAnchorElProject(null)}
                      anchorEl={anchorElProject}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{ marginTop: '4px' }}
                      PaperProps={{
                        style: {
                          width: anchorElProject ? anchorElProject.clientWidth : 'auto',
                          minWidth: '100px',
                        },
                      }}
                    >
                    <MenuItem
                      style={{ justifyContent: 'center' }}
                      onClick={() => {setIsEditing(true); 
                        setAnchorElProject(null);
                        setEditedProjectName(project.selected?.name || '')}}
                    >
                      Editar
                    </MenuItem>
                    <MenuItem
                      style={{ justifyContent: 'center' }}
                      onClick={() => {
                        project.remove(project.selected!.id);
                        setAnchorElProject(null);
                      }}
                    >
                      Excluir
                    </MenuItem>
                    </Menu>
                  </>
                  )}
              </div>
            </div>
            {project.selected && (
              <SectionFields>
                <AlignFields>
                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Sistema / Aplicação *"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="system"
                      onChange={handleChange('system')}
                      disabled={isSubmitting}
                      value={values.system}
                    />
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Épico"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="epic"
                      onChange={handleChange('epic')}
                      disabled={isSubmitting}
                      value={values.epic}
                    />
                  </WrapperFields>

                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Funcionalidade *"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="functionality"
                      onChange={handleChange('functionality')}
                      disabled={isSubmitting}
                      value={values.functionality}
                    />

                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Regras de Negócio"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="rules"
                      onChange={handleChange('rules')}
                      disabled={isSubmitting}
                      value={values.rules}
                    />
                  </WrapperFields>

                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Estória de usuário"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="userStory"
                      onChange={handleChange('userStory')}
                      disabled={isSubmitting}
                      value={values.userStory}
                      maxRows={3}
                      minRows={3}
                    />
                  </WrapperFields>

                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label={values.userStory ? 'Critérios de aceite *' : 'Critérios de aceitação *'}
                      variant="outlined"
                      sx={{ m: 1 }}
                      multiline
                      maxRows={3}
                      minRows={3}
                      name="acceptanceCriteria"
                      onChange={handleChange('acceptanceCriteria')}
                      disabled={isSubmitting}
                      value={values.acceptanceCriteria}
                    />

                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Critérios de negação"
                      variant="outlined"
                      sx={{ m: 1 }}
                      multiline
                      maxRows={3}
                      minRows={3}
                      name="denialCriteria"
                      onChange={handleChange('denialCriteria')}
                      disabled={isSubmitting}
                      value={values.denialCriteria}
                    />
                  </WrapperFields>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <ToggleContent onClick={defineAmount}>
                      <Switch
                        checked={autoAmount}
                        disabled={isSubmitting}
                        sx={{
                          '&.MuiSwitch-root .MuiSwitch-switchBase': {
                            color: '#183E62',
                          },
                          '&.MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track': {
                            backgroundColor: '#183E62',
                          },
                          '&.MuiSwitch-root .Mui-checked': {
                            color: '#FD784E',
                          },
                          '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#FD784E',
                          },
                        }}
                        name="autoAmount"
                      />
                      <ToggleText>Definir manualmente a quantidade de casos de teste</ToggleText>
                    </ToggleContent>
                    <If condition={autoAmount}>
                      <CustomTextField
                        size="small"
                        style={{ width: '15%' }}
                        id="outlined-basic"
                        label="Quantidade de casos de teste"
                        variant="outlined"
                        type="number"
                        sx={{ m: 1 }}
                        name="amount"
                        disabled={isSubmitting}
                        value={values.amount}
                        InputProps={{ inputProps: { min: 1, max: 50, step: 1 } }}
                        onChange={({ target }) => {
                          const value = target.value;

                          if (value === "") {
                            setFieldValue('amount', "");
                            return;
                          }
                      
                          const numericValue = +value;
                          if (numericValue < 1 || numericValue > 50) {
                            return;
                          }
                      
                          setFieldValue('amount', numericValue);
                        }}
                      />
                      {values.amount < 1 && (
                        <div style={{ color: 'white', margin: '8px' }}>
                          A quantidade deve ser pelo menos 1.
                        </div>
                      )}
                    </If>
                  </div>
                </AlignFields>
                <ButtonsContainer>
                  <Tooltip title="Gerar Casos de Teste" placement="left">
                    <ButtonBox>
                      <If condition={!isSubmitting}>
                        <Play onPress={submitForm} color={colors.primary.main} />
                      </If>
                      <If condition={isSubmitting}>
                        <StyledReactLoading width={'5%'} type="bubbles" color={colors.primary.main} />
                      </If>
                    </ButtonBox>
                  </Tooltip>
                </ButtonsContainer>
              </SectionFields>
            )}
          </div>
          {!project.selected ? (
              <TextEmpty style={{ margin: '8px 0' }}>
                Nenhum projeto selecionado até o momento.
              </TextEmpty>
            ) : (
              <GeneratedItemsSpace items={hasGeneratedItemsForSelectedProject()}>
                {!hasGeneratedItemsForSelectedProject() ? (
                  <TextEmpty>Nenhum caso de teste gerado até o momento.</TextEmpty>
                ) : (
                  <>
                    {testCase.generatedItems
                      .filter((item) => item.project.id === project.selected?.id)
                      .map((item, index: number) => (
                        <CardAccordion
                          key={index}
                          title={item.content.summary}
                          content={item.content.description ? item.content.description : ''}
                          feedback={item.feedback}
                          onFeedbackChange={(newFeedback) => {
                            testCase.setFeedback(item.id, newFeedback);
                          }} 
                        />
                    ))}
                  </>
                )}
              </GeneratedItemsSpace>
            )}
        </Board>
      </Background>
    </>
  );
};

export default observer(TestCase);
