import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, useStores, alert } from '~/utils';
import Epic from './Epic';

type Props = {
  response?: EpicType.Response[];
};

const EpicContainer: FC<Props> = () => {
  const { epic } = useStores();

  const onSubmit = async (data: Epic.Forms, { setSubmitting }: any) => {
    if (!data?.application || !data?.objective) {
      alert({
        message: 'Os campos sistema e descrição são obrigatórios.',
        type: 'error',
        position: 'top-center',
      });
      return;
    }
    setSubmitting(true);
    const response = await epic.onEpic(data);
    if(response) {
      const resp = await epic.getEpic({ id: response });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        application: '',
        objective: '',
        amount: 0,
      }}
    >
      <Epic />
    </Formik>
  );
};

export default observer(EpicContainer);
