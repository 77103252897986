import React, { FC, useState, useEffect, useRef } from 'react';
import { Expand, Jira } from '~/assets/svg';
import { AddProject } from '~/components';
import { StyledMenu, StyledMenuItem } from '~/styles';
import {
  Content,
  DropDownHeader,
  DropDownTitle,
  IconContainer,
  Text,
  ScrollBar,
} from './styles';
import { observer, useStores } from '~/utils';
import SearchBar from '../SearchBar';

type Props = {
  type: 'project' | 'epic';
  onClick: () => void;
};

const DropDown: FC<Props> = ({ type, onClick }) => {
  const { epic, project } = useStores();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [searchInput, setSearchInput] = useState('');
  const [visibleItems, setVisibleItems] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const loadCount = 10; // Number of items to load at a time
  const dropdownRef = useRef<HTMLDivElement>(null);
  const filteredProjects = project.items.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredItems = type === 'epic'
    ? epic.items
      .filter((item) =>
        project.selected ? item.project.id === project.selected.id : false
      )
      .filter((item) => item.feedback === 2 || item.feedback === 0)
      .filter((item) =>
        item.content.summary.toLowerCase().includes(searchInput.toLowerCase())
      )
    : project.items.filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    );

  useEffect(() => {
    // Load initial items
    setVisibleItems(filteredItems.slice(0, loadCount));
  }, [filteredItems]);

  const loadMoreItems = () => {
    if (visibleItems.length >= filteredItems.length) {
      setHasMore(false);
      return;
    }
    const newItems = filteredItems.slice(visibleItems.length, visibleItems.length + loadCount);
    setVisibleItems((prev) => [...prev, ...newItems]);
  };

  const handleScroll = () => {
    if (!dropdownRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
      loadMoreItems();
    }
  };

  return (
    <>
      <Content>
        <DropDownHeader
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            setAnchorEl(event.currentTarget as unknown as HTMLButtonElement)
          }
        >
          <DropDownTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {type === 'project' &&
                (project.selected ? (
                  <>
                    {project.selected.type === 'jira' && (
                      <Jira
                        color="#fff"
                        size={16}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    <Text>{project.selected.name}</Text>
                  </>
                ) : (
                  'Selecione um projeto'
                ))}

              {type === 'epic' &&
                (epic.selected ? (
                  <>
                    {epic.selected.type === 'jira' && (
                      <Jira
                        color="#fff"
                        size={16}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    {epic.selected.content.summary}
                  </>
                ) : (
                  'Selecione um épico'
                ))}
            </div>
          </DropDownTitle>
          <IconContainer isOpened={Boolean(anchorEl)}>
            <Expand />
          </IconContainer>
        </DropDownHeader>
      </Content>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : 'auto',
            minWidth: '100px',
          },
        }}
      >
        {type === 'project' && (
          <>
            <SearchBar
              placeholder='Buscar projeto'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <ScrollBar
              items={true}
              ref={dropdownRef}
              style={{ maxHeight: '200px', overflowY: 'auto' }}
              onScroll={handleScroll}
            >
              {visibleItems.map((item, index) => (
                <StyledMenuItem
                  key={index}
                  onClick={() => {
                    project.select(item.id);
                    onClick();
                    setAnchorEl(null);
                  }}
                  selected={project.selected?.id === item.id}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.type === 'jira' && (
                      <Jira
                        color="#fff"
                        size={16}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    <Text>
                      {item.name}
                    </Text>
                  </div>
                </StyledMenuItem>
              ))}
              <AddProject setAnchorEl={setAnchorEl} />
            </ScrollBar>
          </>
        )}
        {type === 'epic' && (
          <>
            <SearchBar
              placeholder='Buscar épico'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <ScrollBar
              items={true}
              ref={dropdownRef}
              style={{ maxHeight: '200px', overflowY: 'auto' }}
              onScroll={handleScroll}
            >
              {visibleItems.map((item, index) => (
                <StyledMenuItem
                  key={index}
                  onClick={() => {
                    epic.select(item.id);
                    onClick();
                    setAnchorEl(null);
                  }}
                  selected={epic.selected?.id === item.id}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.type === 'jira' && (
                      <Jira
                        color="#fff"
                        size={16}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    <Text>
                      {item.content.summary}
                    </Text>
                  </div>
                </StyledMenuItem>
              ))}
            </ScrollBar>
          </>
        )}
      </StyledMenu>
    </>
  );
};

export default observer(DropDown);