import React from 'react';
import { Search, SearchIconWrapper, InputInput } from './styles';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; 
};

const SearchBar: React.FC<Props> = ({ placeholder, value, onChange, onKeyDown }) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <InputInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown} 
      />
    </Search>
  );
};

export default SearchBar;