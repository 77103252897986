export default {
  failure: '#E56657',
  warning: '#FABD59',
  info: '#2D9CDB',
  success: '#27AE60',

  disabled: {
    light: '#ffffff',
    main: '#fff',
    dark: '#fff',
    contrast: '#4f4f4f',
  },
  primary: {
    light: '#fafafa',
    main: '#FD784E',
    dark: '#081E38',
    contrast: '#4f4f4f',
  },
  secondary: {
    light: '#fff',
    main: '#fff',
    dark: '#183E62',
    contrast: '#ffffff',
  },
  tertiary: {
    light: '#fff',
    main: '#fff',
    dark: '#2A557E',
    contrast: '#FFFFFF',
  },
  accent: {
    light: '#fff',
    main: '#fff',
    dark: '#fff',
    contrast: '#ffffff',
  },
} as ColorType;

// export default {
//   failure: '#E56657',
//   warning: '#FABD59',
//   info: '#2D9CDB',
//   success: '#27AE60',

//   disabled: {
//     light: '#ffffff',
//     main: '#fff',
//     dark: '#fff',
//     contrast: '#4f4f4f',
//   },
//   primary: {
//     light: '#fafafa',
//     main: '#4E89FD',
//     dark: '#081E38',
//     contrast: '#64738480',
//   },
//   secondary: {
//     light: '#fff',
//     main: '#F776A4',
//     dark: '#FD784E',
//     contrast: '#647384',
//   },
//   tertiary: {
//     light: '#fff',
//     main: '#69C573',
//     dark: '#fff',
//     contrast: '#FFFFFF',
//   },
//   accent: {
//     light: '#fff',
//     main: '#B47EF8',
//     dark: '#fff',
//     contrast: '#ffffff',
//   },
// } as ColorType;
