import { RouterStore } from 'mobx-react-router';
import TestCaseStore from './testCase.store';
import EpicStore from './epic.store';
import UserStore from './user.store';
import StoryStore from './story.store';
import ProjectStore from './project.store';
import ItemIdStore from './itemId.store';
import JiraImportStore from './jira.import.store';
import JiraExportStore from './jira.export.store';

class RootStore {
  user: UserStore;

  epic: EpicStore;

  testCase: TestCaseStore;

  story: StoryStore;

  jiraImport: JiraImportStore;

  jiraExport: JiraExportStore;

  project: ProjectStore;

  itemId: ItemIdStore;

  constructor() {
    this.user = new UserStore();
    this.testCase = new TestCaseStore();
    this.epic = new EpicStore();
    this.story = new StoryStore();
    this.project = new ProjectStore();
    this.itemId = new ItemIdStore();
    this.jiraExport = new JiraExportStore();
    this.jiraImport = new JiraImportStore();
  }
}

const store = new RootStore();

export { RouterStore, UserStore, TestCaseStore, EpicStore, StoryStore, JiraExportStore, JiraImportStore, ProjectStore, ItemIdStore };

export default store;
