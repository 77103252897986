import { action, observable, makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { IntegrationAlmAPI } from '~/services/api';

export default class ProjectStore implements ItemStore {
  @observable
  issueType: 'Project' = 'Project';

  @observable
  selected?: Project.Type;

  @observable
  items: Project.Type[] = [];
  
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ProjectStore',
      properties: ['selected','items'],
      storage: window.localStorage,
    });
    this.saveItemsLocalStorage();
  };

  @action
  select(id: number): void {
    this.selected = this.items.find(item => item.id === id);
  };

  @action
  add(item: Project.Type): void {
    this.items.push(item);
  };

  @action
  edit(item: Project.Type): void {
    const index = this.items.findIndex(existingItem => existingItem.id === item.id);
    this.items[index] = item;
  };

  @action
  remove(id: number): void {
    this.items = this.items.filter(item => item.id !== id);
    this.selected = undefined;
  };

  @action
  resetItems(): void {
    this.items = [];
  };

  @action
  saveItemsLocalStorage(): void {
    const projectStore = localStorage.getItem('ProjectStore');
    if (projectStore) {
      const projectStoreJson = JSON.parse(projectStore);
      projectStoreJson.items = this.items;
      localStorage.setItem('ProjectStore', JSON.stringify(projectStoreJson));
    }
  }

  @action
  async getJiraProjects(): Promise<boolean> {
    const rsp = await IntegrationAlmAPI.getProjects();
    this.parseProjects(rsp);
    if (rsp.length === 0) {
      return false;
    }
    else {
      return true;
    };
  };

  @action
  parseProjects(apiResponse: any[]): void {
    apiResponse.forEach((project: any) => {
      const item: Project.Type = {
        id: Number(project.id),
        name: project.name,
        type: project.type,
      };
      if (!this.items.some(existingItem => existingItem.id === item.id)) {
        this.add(item);
      }
    });
  };

  @action
  orderProjects(): void {
    const order = ['jira', 'custom'];
    this.items.sort((a, b) => {
      return order.indexOf(a.type) - order.indexOf(b.type);
    });
  };
};
