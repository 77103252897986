import React, { FC } from 'react';
import { Formik } from '~/utils';
import Resource from './Resource';

type Props = {};

const ResourceContainer: FC<Props> = () => {
  const onSubmit = (data: ResourceType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
    }, 2000);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        system: '',
        objective: '',
        epic: '',
        objectiveEpic: '',
        objectiveSystem: '',
        resource: '',
      }}
    >
      <Resource />
    </Formik>
  );
};

export default ResourceContainer;
