import { getTheme, pxToRem, styled } from '~/utils';

// Colors
const primaryDark = getTheme('primary.dark');
const primaryContrast = getTheme('primary.contrast');

// Breakpoints
const inMobile = getTheme('inMobile');

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${inMobile} {
    padding-top: ${pxToRem(50)};
  }
`;

export const Title = styled.h3`
  margin-top: -${pxToRem(20)};
  font-size: ${pxToRem(38)};
  font-weight: bold;
  color: ${primaryDark};

  @media ${inMobile} {
    font-size: ${pxToRem(28)};
  }
`;

export const Paragraph = styled.h3`
  font-size: ${pxToRem(20)};
  color: ${primaryContrast};
  margin-bottom: ${pxToRem(20)};

  @media ${inMobile} {
    font-size: ${pxToRem(18)};
    margin-bottom: ${pxToRem(20)};
  }
`;

export const Img = styled.img`
  @media ${inMobile} {
    width: ${pxToRem(100)};
  }
`;
