import styled from "styled-components";
import { pxToRem } from "~/utils";

  type BoxProps = {
    position: "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight" | "left" | "right";
    show: boolean;
  };

  export const Box = styled.div<BoxProps>` 
    background-color: #fff;
    padding: 4px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: ${pxToRem(14)};
    position: absolute;
    z-index: 1000;
    width: 100px;
    border-radius: ${pxToRem(4)};
    ${props => positionStyles(props.position, props.show)};
    `;

  const positionStyles = (position: string, show: boolean) => {
    const slideInBottom = `
      @keyframes slideIn {
        from {
          transform: translateY(-20%);
        }
        to {
          transform: translateY(0);
        }
      }
    `
    const slideInTop = `
      @keyframes slideIn {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(20%);
        }
      }
    `
    switch (position) {
      case 'topCenter':
        return `
          transform: translateX(-50%);
          bottom: 100%;
          left: 50%;
          margin-bottom: 8px;
          ${slideInTop}
          animation: slideIn 0.1s forwards;
        `;
      case 'topLeft':
        return `
          bottom: 100%;
          right: 0;
          margin-bottom: 8px;
          ${slideInTop}
          animation: slideIn 0.1s forwards;
        `;
      case 'topRight':
        return `
          bottom: 100%;
          left: 0;
          margin-bottom: 8px;
          ${slideInTop}
          animation: slideIn 0.1s forwards;
        `;
      case 'bottomCenter':
        return `
          transform: translateX(-50%);
          top: 100%;
          left: 50%;
          margin-top: 8px;
          ${slideInBottom}
          animation: slideIn 0.1s forwards;
        `;
      case 'bottomLeft':
        return `
          top: 100%;
          right: 0;
          margin-top: 8px;
          ${slideInBottom}
          animation: slideIn 0.1s forwards;
        `;
      case 'bottomRight':
        return `
          top: 100%;
          left: 0;
          margin-top: 8px;
          ${slideInBottom}
          animation: slideIn 0.1s forwards;
        `;
      case 'left':
        return `
          top: 0;
          right: 100%;
          margin-right: 8px;
        `;
      case 'right':
        return `
          top: 0;
          left: 100%;
          margin-left: 8px;
        `;
    }
  }

  export const Option = styled.span`
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: 2px;

    &:hover {
      background-color: #E8F7FD;
    }
  `
