import { getTheme, styled } from '~/utils';
import { FormControl, InputLabel } from '@material-ui/core';

// Colors
const primaryMain = getTheme('primary.main');
const secondaryDark = getTheme('secondary.dark');

export const CustomFormControl = styled(FormControl)`
  & label {
    font-size: 1em;
    color: #fff;
  }
  & .MuiInputBase-root {
    background-color: ${secondaryDark};
    color: #fff;
  }
  & .MuiOutlinedInput-root {
    color: #fff;
  }
  & label.Mui-focused {
    color: ${primaryMain};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${primaryMain};
    }
  }
`;

export const CustomInputLabel = styled(InputLabel)`
  font-size: 0.9em !important;
`;
