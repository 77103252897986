import styled from 'styled-components';
import { pxToRem } from '~/utils';

export const Search = styled.div`
  position: relative;
  border-radius: ${pxToRem(4)};
  width: 100%;
`;

export const SearchIconWrapper = styled.div`
  padding: 0 16px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputInput = styled.input`
  padding: 8px 8px 8px 0; 
  padding-left: calc(1em + 32px); 
  margin: 0px 8px;
  border-radius: ${pxToRem(4)};
  width: calc(100% - ${pxToRem(16)});
  color: #fafafa;
  background-color: #081E38;
  border: none;
  &:focus {
    outline: none;
  }
`;



